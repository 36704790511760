import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form__group form__group--no-mb"
}
const _hoisted_2 = { class: "form__selector-buttons" }
const _hoisted_3 = { class: "form__selector-button form__selector-button--half form__selector-button--left-aligned form__selector-button--reduced-pv" }
const _hoisted_4 = ["checked"]
const _hoisted_5 = { for: "not-today" }
const _hoisted_6 = { class: "sublabel" }
const _hoisted_7 = ["id", "value", "checked"]
const _hoisted_8 = ["for"]
const _hoisted_9 = { class: "sublabel" }
const _hoisted_10 = { class: "form__group" }
const _hoisted_11 = ["checked"]
const _hoisted_12 = { class: "form__selector-card__other" }
const _hoisted_13 = { class: "flex flex--wrap flex--align-center" }
const _hoisted_14 = { class: "form__input-icon-wrapper form__input-icon-wrapper--invert icon icon--pound" }
const _hoisted_15 = ["value"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.has_event_donation_options() && $setup.ticket_cost > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[6] || (_cache[6] = _createElementVNode("p", { class: "h3 h3--mb-8" }, " Are you able to add a donation? ", -1 /* HOISTED */)),
        _cache[7] || (_cache[7] = _createElementVNode("p", { class: "p" }, " By giving an additional donation you can really make a difference to our churches in the UK ", -1 /* HOISTED */)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.error_messages, (message, index) => {
          return (_openBlock(), _createElementBlock("p", {
            key: index,
            class: "form__error-message mb-32"
          }, _toDisplayString(message), 1 /* TEXT */))
        }), 128 /* KEYED_FRAGMENT */)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("input", {
              id: "not-today",
              checked: $setup.has_donation_amount(0) && !$setup.donation_amount_other_selected(),
              type: "radio",
              name: "donation_value",
              value: "0",
              onClick: _cache[0] || (_cache[0] = $event => ($setup.updateEventDonationAmount( $event )))
            }, null, 8 /* PROPS */, _hoisted_4),
            _createElementVNode("label", _hoisted_5, [
              _cache[3] || (_cache[3] = _createTextVNode("Not today")),
              _createElementVNode("span", _hoisted_6, "Total will be " + _toDisplayString($setup.formatCurrency( $setup.ticket_cost )), 1 /* TEXT */)
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.event_donation_amounts, (event_donation_amount) => {
            return (_openBlock(), _createElementBlock("div", {
              key: event_donation_amount.id,
              class: "form__selector-button form__selector-button--half form__selector-button--left-aligned form__selector-button--reduced-pv"
            }, [
              _createElementVNode("input", {
                id: event_donation_amount.amount,
                value: event_donation_amount.amount,
                checked: $setup.has_donation_amount(event_donation_amount.amount),
                name: "donation_value",
                type: "radio",
                onClick: _cache[1] || (_cache[1] = $event => ($setup.updateEventDonationAmount( $event )))
              }, null, 8 /* PROPS */, _hoisted_7),
              _createElementVNode("label", {
                for: event_donation_amount.amount
              }, [
                _createTextVNode(" +" + _toDisplayString($setup.formatCurrency( event_donation_amount.amount * 100 )), 1 /* TEXT */),
                _createElementVNode("span", _hoisted_9, "Total will be " + _toDisplayString($setup.formatCurrency( $setup.ticket_cost + ( event_donation_amount.amount * 100 ) )), 1 /* TEXT */)
              ], 8 /* PROPS */, _hoisted_8)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _createCommentVNode(" Other Amount "),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", {
            id: "js-other-amount",
            class: _normalizeClass(["form__selector-card form__selector-card--other form__selector-card--other--inline js-other-amount", { 'form__selector-card--other--open': $setup.donation_amount_other_selected() }])
          }, [
            _createElementVNode("input", {
              id: "donate-other",
              checked: $setup.has_other_amount() || ( $setup.has_donation_amount(0) && $setup.donation_amount_other_selected() ),
              type: "radio",
              name: "donation_value",
              value: "custom",
              "data-donate-other": "",
              onClick: _cache[2] || (_cache[2] = $event => ($setup.updateEventDonationAmount( $event )))
            }, null, 8 /* PROPS */, _hoisted_11),
            _cache[5] || (_cache[5] = _createElementVNode("label", { for: "donate-other" }, [
              _createElementVNode("span", { class: "form__selector-card__content" }, [
                _createElementVNode("span", { class: "form__selector-card__heading" }, "Enter another amount?"),
                _createElementVNode("span", { class: "form__selector-card__p" }, [
                  _createElementVNode("b", null, "Choose how much you'd like to give")
                ])
              ])
            ], -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _cache[4] || (_cache[4] = _createElementVNode("label", {
                  for: "donation_value_custom",
                  class: "invisible"
                }, " Enter another amount ", -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("input", {
                    ref: "donation_value_custom",
                    type: "text",
                    class: "form__input form__input--invert sync-output",
                    name: "donation_value_custom",
                    "data-custom-amount": "",
                    value: $setup.has_other_amount() ? $setup.donation_cost / 100 : 0,
                    onChange: $setup.updateEventDonationAmount
                  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_15)
                ])
              ])
            ])
          ], 2 /* CLASS */)
        ])
      ]))
    : _createCommentVNode("v-if", true)
}