<template>
	<!-- Ticket Selector -->
	<div class="mb-64">
		<p
			v-for="message, index in error_messages"
			:key="index"
			class="form__error-message mb-32"
		>
			{{ message }}
		</p>
		<div
			v-for="remaining_ticket_type, attendee_type_id in remaining_ticket_types"
			:key="attendee_type_id"
			class="form__ticket-selector"
			:class="{ 'form__ticket-selector--sold-out': !tickets_remaining( attendee_type_id ) && !tickets_in_session( attendee_type_id ) }"
		>
			<label for="adult">{{ remaining_ticket_type.type }} Ticket - &pound;{{ remaining_ticket_type.cost }}</label>
			<div
				v-if="tickets_in_session( attendee_type_id ) || tickets_remaining( attendee_type_id )"
				class="form__ticket-selector__input-wrapper"
			>
				<button
					type="button"
					aria-label="Decrease ticket count"
					class="form__ticket-selector__button form__ticket-selector__button--minus"
					:disabled="!tickets_in_session( attendee_type_id ) || loading"
					@click="removeTicketBooking( attendee_type_id )"
				/>
				<!-- I've made this a span because the user isn't going to type in a number, I don't think -->
				<!-- So really it just displays the number -->
				<span
					v-show="!loading"
					class="form__ticket-selector__events-qty"
				>
					{{ current_session.tickets[attendee_type_id] ? current_session.tickets[attendee_type_id].quantity : 0 }}
				</span>
				<!-- Input has min and max set - recommended -->
				<!-- <input
					id="{{ attendee_type_id }}"
					type="number"
					min="0"
					max="{{ remaining_ticket_type.remaining }}"
					value="0"
					name="{{ attendee_type_id }}-tickets"
					class="form__ticket-selector__input"
				> -->
				<img
					v-show="loading"
					src="/assets/img/spin-2.svg"
					alt="Loading"
					class="form__ticket-selector__events-qty"
				>
				<button
					type="button"
					aria-label="Increase ticket count"
					class="form__ticket-selector__button form__ticket-selector__button--add"
					:disabled="!tickets_remaining( attendee_type_id ) || loading"
					@click="addTicketBooking( attendee_type_id )"
				/>
			</div>
			<div
				class="form__ticket-selector__tag-wrapper"
			>
				<div
					v-if="tickets_in_session( attendee_type_id ) || tickets_remaining( attendee_type_id )"
					class="tag"
				>
					{{ remaining_ticket_type.remaining }} tickets left
				</div>
				<div
					v-else
					class="tag"
				>
					Sold out
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import ApiClient from '../helpers/ApiClient';
import { store } from '../helpers/store.js';

const current_session = computed( () => {
	return store.session ?? null;
} );

const error_messages = ref( [ ] );
const remaining_ticket_types = ref( [ ] );
const loading = ref( false );

function tickets_remaining( ticket_type ) { //eslint-disable-line
	const remaining_in_total = remaining_ticket_types.value[ticket_type].remaining;

	return remaining_in_total > 0;
}

function tickets_in_session( ticket_type ) {
	if ( null === current_session.value ) {
		return;
	}

	const in_session_ticket_item = current_session.value.tickets[ticket_type] ?? null;

	if ( null === in_session_ticket_item ) {
		return false;
	}

	return in_session_ticket_item.quantity > 0;
}

async function addTicketBooking( attendee_type_id ) {
	error_messages.value = [];
	await ApiClient.addTicketBooking(
		{
			'attendee_type_id': attendee_type_id
		}
	)
		.then( response => {
			refreshRemainingTicketTypes();
			store.updateSession( response );
		} )
		.catch( error => {
			error_messages.value = error.response.data; //eslint-disable-line
		} );
}

async function removeTicketBooking( attendee_type_id ) {
	error_messages.value = [];
	await ApiClient.removeTicketBooking(
		{
			'attendee_type_id': attendee_type_id
		}
	)
		.then( response => {
			refreshRemainingTicketTypes();
			store.updateSession( response );
		} )
		.catch( error => {
			console.log( error ); //eslint-disable-line
		} );
}

async function refreshRemainingTicketTypes() {
	loading.value = true;
	await ApiClient.getRemainingTicketTypes()
		.then( response => {
			remaining_ticket_types.value = response;
		} )
		.catch( error => {
			console.log( error ); //eslint-disable-line
		} );

	loading.value = false;
}

onMounted( async() => {
	await ApiClient.getSession()
		.then( ( response ) => {
			store.updateSession( response );
		} )
		.catch( error => {
			console.log( error ); //eslint-disable-line
		} );

	await refreshRemainingTicketTypes();
} );

</script>
