import { reactive } from 'vue';

export const store = reactive( {
	session: null,
	event_donation_amounts: null,

	async updateSession( data ) {
		this.session = data;
	},

	async updateEventDonationAmounts( data ) {
		this.event_donation_amounts = data;
	},
} );
