
export function getWindowHeight() {
	return ( window.innerHeight || document.documentElement.clientHeight );
}

export function getWindowWidth() {
	return ( window.innerWidth || document.documentElement.clientWidth );
}

export function throttle( callback, limit ) {
	let tick = false;
	return function() {
		if ( !tick ) {
			callback.call();
			tick = true;
			setTimeout( () => {
				tick = false;
			}, limit );
		}
	};
}

export function formatCurrency( amount_in_pence ) {
	if ( amount_in_pence === 0 || amount_in_pence === '0' ) {
		return 'Free';
	}

	let converted_amount = parseInt( amount_in_pence );
	converted_amount = converted_amount < 100 ? ( '0' + converted_amount.toString() ) : converted_amount.toString();

	return `£${ converted_amount.slice( 0, -2 ) }.${ converted_amount.slice( -2 ) }`;
}
