<template>
	<div>
		<h3 class="overview-sidebar__heading">
			Ticket selection
		</h3>

		<div v-if="has_tickets()">
			<p
				v-for="ticket in tickets"
				:key="ticket.attendee_type_id"
				class="flex flex--justify-space-between"
			>
				<span>x{{ ticket.quantity }} {{ ticket.name }} ticket</span>
				<span>{{ formatCurrency( ticket.cost_per_ticket ) }}</span>
			</p>
		</div>
		<div v-else>
			<p>No tickets have been selected</p>
		</div>

		<div class="overview-sidebar__totals">
			<p class="overview-sidebar__totals__total flex flex--justify-space-between">
				<span>Subtotal</span>
				<span>{{ formatCurrency( ticket_cost ) }}</span>
			</p>
			<p
				v-if="donation_cost > 0"
				class="overview-sidebar__totals__total flex flex--justify-space-between"
			>
				<span>Donation</span>
				<span>{{ formatCurrency( donation_cost ) }}</span>
			</p>
			<p
				v-if="total_cost > 0"
				class="overview-sidebar__totals__total--grand flex flex--justify-space-between"
			>
				<span>Total</span>
				<span>{{ formatCurrency( total_cost ) }}</span>
			</p>
		</div>
	</div>
</template>

<script setup>
import { formatCurrency } from '../../helpers';
import { computed, onMounted } from 'vue';
import ApiClient from '../helpers/ApiClient';
import { store } from '../helpers/store.js';

const ticket_cost = computed( () => {
	return store.session ? store.session.ticket_value : 0;
} );

const donation_cost = computed( () => {
	return store.session ? store.session.donation_value : 0;
} );

const total_cost = computed( () => {
	return store.session ? store.session.total_value : 0;
} );

const tickets = computed( () => {
	return store.session ? store.session.tickets : [];
} );

function has_tickets() {
	if ( null === store.session ) {
		return false;
	}

	let int_tickets = 0;
	const tickets = Object.values( store.session.tickets );

	for ( let i = 0; i < tickets.length; i++ ) {
		int_tickets += tickets[i].quantity;
	}

	return int_tickets > 0;
}

onMounted( async() => {
	if ( null === store.session ) {
		await ApiClient.getSession()
			.then( ( response ) => {
				store.updateSession( response );
			} )
			.catch( error => {
				console.log( error ); //eslint-disable-line
			} );
	}
} );

</script>
