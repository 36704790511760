<template>
	<div
		v-if="has_event_donation_options() && ticket_cost > 0"
		class="form__group form__group--no-mb"
	>
		<p class="h3 h3--mb-8">
			Are you able to add a donation?
		</p>
		<p class="p">
			By giving an additional donation you can really make a difference to our churches in the UK
		</p>
		<p
			v-for="message, index in error_messages"
			:key="index"
			class="form__error-message mb-32"
		>
			{{ message }}
		</p>
		<div class="form__selector-buttons">
			<div class="form__selector-button form__selector-button--half form__selector-button--left-aligned form__selector-button--reduced-pv">
				<input
					id="not-today"
					:checked="has_donation_amount(0) && !donation_amount_other_selected()"
					type="radio"
					name="donation_value"
					value="0"
					@click="updateEventDonationAmount( $event )"
				>
				<label for="not-today">Not today<span class="sublabel">Total will be {{ formatCurrency( ticket_cost ) }}</span></label>
			</div>
			<div
				v-for="event_donation_amount in event_donation_amounts"
				:key="event_donation_amount.id"
				class="form__selector-button form__selector-button--half form__selector-button--left-aligned form__selector-button--reduced-pv"
			>
				<input
					:id="event_donation_amount.amount"
					:value="event_donation_amount.amount"
					:checked="has_donation_amount(event_donation_amount.amount)"
					name="donation_value"
					type="radio"
					@click="updateEventDonationAmount( $event )"
				>
				<label
					:for="event_donation_amount.amount"
				>
					+{{ formatCurrency( event_donation_amount.amount * 100 ) }}<span class="sublabel">Total will be {{ formatCurrency( ticket_cost + ( event_donation_amount.amount * 100 ) ) }}</span>
				</label>
			</div>
		</div>
		<!-- Other Amount -->
		<div class="form__group">
			<div
				id="js-other-amount"
				class="form__selector-card form__selector-card--other form__selector-card--other--inline js-other-amount"
				:class="{ 'form__selector-card--other--open': donation_amount_other_selected() }"
			>
				<input
					id="donate-other"
					:checked="has_other_amount() || ( has_donation_amount(0) && donation_amount_other_selected() )"
					type="radio"
					name="donation_value"
					value="custom"
					data-donate-other
					@click="updateEventDonationAmount( $event )"
				>
				<label for="donate-other">
					<span class="form__selector-card__content">
						<span class="form__selector-card__heading">Enter another amount?</span>
						<span class="form__selector-card__p"><b>Choose how much you'd like to give</b></span>
					</span>
				</label>
				<div class="form__selector-card__other">
					<div class="flex flex--wrap flex--align-center">
						<label
							for="donation_value_custom"
							class="invisible"
						>
							Enter another amount
						</label>
						<div class="form__input-icon-wrapper form__input-icon-wrapper--invert icon icon--pound">
							<input
								ref="donation_value_custom"
								type="text"
								class="form__input form__input--invert sync-output"
								name="donation_value_custom"
								data-custom-amount
								:value="has_other_amount() ? donation_cost / 100 : 0"
								@change="updateEventDonationAmount"
							>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { formatCurrency } from '../../helpers';
import { ref, computed, onMounted } from 'vue';
import ApiClient from '../helpers/ApiClient';
import { store } from '../helpers/store.js';

const ticket_cost = computed( () => {
	return store.session ? store.session.ticket_value : 0;
} );

const donation_cost = computed( () => {
	return store.session ? store.session.donation_value : 0;
} );

const event_donation_amounts = computed( () => {
	return store.event_donation_amounts ? store.event_donation_amounts : [];
} );

const donation_value_custom = ref( null );

const donation_amount_other = ref( has_other_amount() );

const error_messages = ref( [ ] );

function has_event_donation_options() {
	if ( null === store.event_donation_amounts ) {
		return false;
	}

	let int_donation_amounts = 0;
	const donation_amounts = Object.values( store.event_donation_amounts );

	for ( let i = 0; i < donation_amounts.length; i++ ) {
		if ( donation_amounts[i].amount > 0 ) {
			int_donation_amounts++;
		}
	}

	return int_donation_amounts > 0;
}

function has_donation_amount( donation_amount ) {
	return donation_cost.value === ( donation_amount * 100 );
}

function has_other_amount() {
	if ( null === donation_cost.value || donation_cost.value === 0  ) {
		return false;
	}

	const donation_amounts = Object.values( store.event_donation_amounts );

	for ( let i = 0; i < donation_amounts.length; i++ ) {
		if ( ( donation_amounts[i].amount * 100 ) === donation_cost.value ) {
			return false;
		}
	}

	return true;
}

async function updateEventDonationAmount( $event ) {
	const event_donation_amount = $event.target.value;
	if ( event_donation_amount === 'custom' ) {
		donation_amount_other.value = true;

		donation_value_custom.value.focus();
	} else {
		error_messages.value = [];
		await ApiClient.updateDonationAmount(
			{
				'donation_amount': event_donation_amount
			}
		)
			.then( response => {
				store.updateSession( response );
				donation_amount_other.value = has_other_amount();
			} )
			.catch( error => {
				error_messages.value = error.response.data; //eslint-disable-line
			} );
	}
}

function donation_amount_other_selected() {
	return donation_amount_other.value;
}

onMounted( async() => {
	if ( null === store.event_donation_amounts ) {
		await ApiClient.getDonationOptions()
			.then( ( response ) => {
				store.updateEventDonationAmounts( response );
			} )
			.catch( error => {
				console.log( error ); //eslint-disable-line
			} );
	}
} );

</script>
