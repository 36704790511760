import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "overview-sidebar__totals" }
const _hoisted_4 = { class: "overview-sidebar__totals__total flex flex--justify-space-between" }
const _hoisted_5 = {
  key: 0,
  class: "overview-sidebar__totals__total flex flex--justify-space-between"
}
const _hoisted_6 = {
  key: 1,
  class: "overview-sidebar__totals__total--grand flex flex--justify-space-between"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[4] || (_cache[4] = _createElementVNode("h3", { class: "overview-sidebar__heading" }, " Ticket selection ", -1 /* HOISTED */)),
    ($setup.has_tickets())
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tickets, (ticket) => {
            return (_openBlock(), _createElementBlock("p", {
              key: ticket.attendee_type_id,
              class: "flex flex--justify-space-between"
            }, [
              _createElementVNode("span", null, "x" + _toDisplayString(ticket.quantity) + " " + _toDisplayString(ticket.name) + " ticket", 1 /* TEXT */),
              _createElementVNode("span", null, _toDisplayString($setup.formatCurrency( ticket.cost_per_ticket )), 1 /* TEXT */)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("p", null, "No tickets have been selected", -1 /* HOISTED */)
        ]))),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, [
        _cache[1] || (_cache[1] = _createElementVNode("span", null, "Subtotal", -1 /* HOISTED */)),
        _createElementVNode("span", null, _toDisplayString($setup.formatCurrency( $setup.ticket_cost )), 1 /* TEXT */)
      ]),
      ($setup.donation_cost > 0)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
            _cache[2] || (_cache[2] = _createElementVNode("span", null, "Donation", -1 /* HOISTED */)),
            _createElementVNode("span", null, _toDisplayString($setup.formatCurrency( $setup.donation_cost )), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true),
      ($setup.total_cost > 0)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
            _cache[3] || (_cache[3] = _createElementVNode("span", null, "Total", -1 /* HOISTED */)),
            _createElementVNode("span", null, _toDisplayString($setup.formatCurrency( $setup.total_cost )), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}