import './ajax.min.js';

export class LoadOMatic {
	constructor( options ) {
		this.current_page = options.currentPage || 1;
		this.total_pages = options.totalPages;
		this.load_url = options.loadUrl;
		this.extra_query = options.extraQuery || '';
		this.next_page = this.current_page + 1;
		this.handle_trigger_dly = 0;

		this.append_container = document.querySelector( '.loadomatic' );
		this.message = document.querySelector( '.loadomatic__message' );
		this.trigger = document.querySelector( '.loadomatic__trigger' );
		// this.current_scroll_position = window.scrollY;

		this.callbacks = [];

		// initial load
		this.trigger.classList.add( 'loadomatic__trigger--disabled' );
		this.handleTrigger( 0 );

		this.trigger.addEventListener( 'click', ( event ) => {
			this.loadMore();
			event.preventDefault();
		} );
	}

	loadPage( requested_url, replace_all = true, scroll_to_container = true ) {
		if ( this.reached_end ) {
			return;
		}
		this.message.classList.remove( 'loadomatic__message--hidden' );
		const url = requested_url + ( !requested_url.includes( 'loadmore=1' ) ? ( requested_url.includes( '?' ) ? '&loadmore=1' : '?loadmore=1' ) : '' );
		const loadomatic_container_position = this.append_container.getBoundingClientRect().top + window.scrollY - 73 - 80;
		ajax( {
			type: 'get',
			url: url,
			data: {},
			headers: [
				['X-LoadOMatic', 'yes'],
			],
			timeout: 5000,
			onSuccess: ( data, status ) => {
				if (
					data.trim() === ''
					|| ( replace_all === false && status === 202 )
				) {
					this.reached_end = true;
				} else {
					this.appendLoadomaticContainer( data, replace_all );
					this.updateWindowLocation( url );
				}
				
				this.handleTrigger();
				this.registerPaginationButtons();

				if ( scroll_to_container ) {
					window.scrollTo( 0, loadomatic_container_position );
				}
			},
			onFailure: this.handleFailure(),
		} );
	}

	appendLoadomaticContainer( data, replace_all = true ) {

		if ( replace_all ) {
			this.append_container.innerHTML = data;
		} else {
			this.append_container.innerHTML += data;
		}
		for ( let i = 0; i < this.callbacks.length; i++ ) {
			this.callbacks[i]();
		}
	}

	updateWindowLocation( url ) {
		if ( window.history.pushState ) {
			window.history.pushState( null, null, url );
		}
	}

	registerPaginationButtons() {
		const pagination_container = document.querySelector( '.pagination--async' );
		const pagination_buttons = pagination_container.querySelectorAll( 'button' );
		const results_container = document.querySelector( '.loadomatic' );
		const pagination_message = document.getElementById( 'js-pagination-message' );
		
		pagination_buttons.forEach( ( button ) => {
			button.addEventListener( 'click', ()=> {
				const requested_url = button.dataset.url;
				if ( pagination_message ) {
					pagination_message.classList.add( 'removed' );
				}
				this.loadPage( requested_url );
			});
		} );
	
		if ( !results_container ) {
			return;
		}
	}

	handleTrigger( delay ) {
		if ( this.total_pages <= this.current_page ) {
			this.trigger.classList.add( 'loadomatic__trigger--hidden' );
			this.message.classList.add( 'loadomatic__message--hidden' );
			return;
		}
		this.trigger.classList.remove( 'loadomatic__trigger--hidden' );
		setTimeout( () => {
			this.trigger.classList.remove( 'loadomatic__trigger--disabled' );
		}, delay );
	}

	handleFailure() {
		return () => {
			this.message.classList.remove( 'loadomatic__message--hidden' );
			this.trigger.classList.add( 'loadomatic__trigger--hidden' );
			this.message.querySelector( 'p' ).innerHTML = 'Sorry, there was a problem retrieving content.';
		}
	}

	urlCreator( page, loadmore ) {
		// regex replaces should be dealt with before getting to this point, but just in case
		let extra_query = this.extra_query.replace( /&{0,1}page=\d+/, '' ).replace( /amp;/, '' );
		return ( this.load_url + '?' + ( extra_query.length ? extra_query + '&' : '' ) + 'page=' + page + ( loadmore ? '&loadmore=true' : '' ) );
	}

	addCallback( fx ) {
		this.callbacks.push( fx );
	}
}